// Generated by Framer (c75d380)

import { addFonts, cx, CycleVariantState, getFonts, optimizeAppear, optimizeAppearTransformTemplate, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import ButtonsSecondaryButton from "./ZtXleVxvb";
const ButtonsSecondaryButtonFonts = getFonts(ButtonsSecondaryButton);
const MotionDivWithFX = withFX(motion.div);

const cycleOrder = ["kvaeX6hgD"];

const serializationHash = "framer-nhzRY"

const variantClassNames = {kvaeX6hgD: "framer-v-rlfj84"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transition1 = {damping: 30, delay: 0.7, mass: 1, stiffness: 186, type: "spring"}

const animation = {opacity: 0, rotate: 0, scale: 0.9, transition: transition1, x: 0, y: 100}

const animation1 = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, transition: transition1, x: 0, y: 0}

const animation2 = {opacity: 0.001, rotate: 0, scale: 0.9, x: 0, y: 100}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "kvaeX6hgD", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const transforms = restProps.transformTemplate?.(undefined, "") ?? ""

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><MotionDivWithFX {...restProps} __framer__presenceAnimate={optimizeAppear("animate", "rlfj84", animation1, undefined)} __framer__presenceExit={animation} __framer__presenceInitial={optimizeAppear("initial", "rlfj84", animation2, undefined)} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-rlfj84", className, classNames)} data-framer-appear-id={"rlfj84"} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"kvaeX6hgD"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} transformTemplate={optimizeAppearTransformTemplate("rlfj84", (_, t) => `${transforms} perspective(1200px) ${t}`)}><motion.div className={"framer-1xnq2iv-container"} layoutDependency={layoutDependency} layoutId={"FQnzsBdo3-container"}><ButtonsSecondaryButton aw9hCqzCY={"Get Access"} FsJQLC6Zo={false} height={"100%"} id={"FQnzsBdo3"} layoutId={"FQnzsBdo3"} qUWpVfITp={false} width={"100%"}/></motion.div></MotionDivWithFX></Transition>
</LayoutGroup>)

});

const css = [".framer-nhzRY[data-border=\"true\"]::after, .framer-nhzRY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nhzRY.framer-43of6i, .framer-nhzRY .framer-43of6i { display: block; }", ".framer-nhzRY.framer-rlfj84 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 16px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-nhzRY .framer-1xnq2iv-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-nhzRY.framer-rlfj84 { gap: 0px; } .framer-nhzRY.framer-rlfj84 > * { margin: 0px; margin-left: calc(16px / 2); margin-right: calc(16px / 2); } .framer-nhzRY.framer-rlfj84 > :first-child { margin-left: 0px; } .framer-nhzRY.framer-rlfj84 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 41
 * @framerIntrinsicWidth 120
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerBFCyWOeIk: React.ComponentType<Props> = withCSS(Component, css, "framer-nhzRY") as typeof Component;
export default FramerBFCyWOeIk;

FramerBFCyWOeIk.displayName = "Buttons/Hero Buttons";

FramerBFCyWOeIk.defaultProps = {height: 41, width: 120};

addFonts(FramerBFCyWOeIk, [...ButtonsSecondaryButtonFonts])